var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:("fixed inset-0 flex md:hidden transition-all ease-in-out duration-700 " + (_vm.open ? 'z-50' : '-z-10')),attrs:{"role":"dialog","aria-modal":"true"}},[_c('div',{class:("fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 " + (_vm.open ? "opacity-100" : "opacity-0")),attrs:{"aria-hidden":"true"}}),_c('div',{class:("relative flex-1 flex flex-col w-full h-full pb-4 bg-white transition ease-in-out duration-300 transform " + (_vm.open ? "translate-x-0" : "-translate-x-full"))},[_c('div',{staticClass:"flex flex-row justify-between content-center"},[_c('img',{staticClass:"h-12 pl-4 pt-3",attrs:{"src":"/logo/logo-wide.png","alt":"Workflow"}}),_c('div',{staticClass:"pt-3"},[_c('button',{staticClass:"\n              ml-1\n              flex\n              items-center\n              justify-center\n              h-12\n              w-12\n              rounded-full\n              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white\n            ",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close sidebar")]),_c('svg',{staticClass:"h-12 w-12 text-brand-purple-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 36 36","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])])]),_c('div',{staticClass:"mt-8 flex-1 flex flex-col justify-between h-0 overflow-y-auto"},[_c('nav',{staticClass:"flex-1 px-2 pb-4 space-y-1"},_vm._l((_vm.items),function(item,index){return _c('button',{key:index,class:("text-gray-900 group flex items-center p-4 text-sm font-medium rounded-md font-serif " + (_vm.$router.currentRoute.name === item.router_name
                  ? 'font-extrabold'
                  : 'font-light')),on:{"click":function () {
                _vm.close();
                _vm.$router.push({ name: item.router_name }).catch(function () {});
              }}},[_c('n-icon',{staticClass:"mr-4",attrs:{"name":item.icon,"medium":""}}),_c('n-html',{attrs:{"text":_vm.$t(item.locale_key)}})],1)}),0),_c('div',{staticClass:"px-6 w-full"},[_c('DamageTheftButtons',{staticClass:"flex flex-row justify-between gap-2 pb-8",attrs:{"extra-button-action":function () { return _vm.close(); }}}),_c('div',{staticClass:"border-b border-gray-300 pb-4"},[_c('languageSelector')],1),_c('div',{staticClass:"w-full font-serif pt-4 text-gray-500 font-light leading-5 text-sm"},[_c('div',{staticClass:"flex flex-row"},[_c('n-html',{attrs:{"text":_vm.$t('layout.common.sidebar.last_updated')}}),_vm._v(" : "),_c('div',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(new Date().toLocaleDateString("nl-NL", { hour: "numeric", minute: "numeric" }))+" ")])],1)])],1)])])]),_c('div',{staticClass:"hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"},[_c('div',{staticClass:"\n        flex flex-col flex-grow\n        border-r border-gray-100\n        pt-5\n        bg-white\n        overflow-y-auto\n      "},[_vm._m(0),_c('div',{staticClass:"mt-5 flex-grow flex flex-col"},[_c('nav',{staticClass:"flex-1 px-0.5 pb-4"},[_vm._l((_vm.items),function(item,index){return _c('button',{key:index,class:("text-brand-purple-500 group flex items-center px-7 my-6 text-sm font-medium font-serif cursor-pointer " + (_vm.$router.currentRoute.name === item.router_name
                  ? 'pl-6 font-extrabold border-l-2 border-brand-purple-500'
                  : 'font-light')),on:{"click":function($event){_vm.$router
                .push({ name: item.router_name })
                .catch(function () {})
                .then(function () {
                  _vm.close();
                })}}},[_c('n-icon',{staticClass:"mr-4",attrs:{"name":item.icon,"medium":""}}),_c('n-html',{attrs:{"text":_vm.$t(item.locale_key)}})],1)}),_c('DamageTheftButtons',{staticClass:"lg:hidden flex flex-col gap-4 px-6"})],2),_c('div',{staticClass:"mt-auto p-6 w-full"},[_c('div',{staticClass:"border-b border-gray-300 pb-4"},[_c('languageSelector')],1),_c('div',{staticClass:"w-full text-center font-serif pt-4 text-gray-500 font-light leading-5 text-sm"},[_c('div',[_c('n-html',{attrs:{"text":_vm.$t('layout.common.sidebar.last_updated')}}),_vm._v(" : ")],1),_c('div',[_vm._v(" "+_vm._s(this.format_date( this.customer.last_synchronized_at, "DD-MM-YYYY HH:mm" ))+" ")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center flex-shrink-0 px-4"},[_c('img',{staticClass:"p-10 pt-3 mb-8",attrs:{"src":"/logo/logo-sidebar.png","alt":"Workflow"}})])}]

export { render, staticRenderFns }