<template>
  <div class="font-serif">
    <div v-if="!this.isMobile(w.width)" class="desktop">
      <desktop />
    </div>
    <div v-else class="mobile">
      <desktop />
    </div>
  </div>
</template>

<script>
// import mobile from "./mobile";
import desktop from "./desktop";
import layout from "../mixins/layout";

export default {
  components: {
    // mobile,
    desktop
  },
  name: "layouts.switcher",
  mixin: [layout],
  data: () => ({
    w: {
      width: 0,
      height: 0
    }
  }),
  methods: {
    handleResize() {
      this.w.width = window.innerWidth;
      this.w.height = window.innerHeight;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
