<template>
  <div>
    <div
      :class="
        `fixed inset-0 flex md:hidden transition-all ease-in-out duration-700 ${
          open ? 'z-50' : '-z-10'
        }`
      "
      role="dialog"
      aria-modal="true"
    >
      <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        :class="
          `fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 ${
            open ? `opacity-100` : `opacity-0`
          }`
        "
        aria-hidden="true"
      ></div>

      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div
        :class="
          `relative flex-1 flex flex-col w-full h-full pb-4 bg-white transition ease-in-out duration-300 transform ${
            open ? `translate-x-0` : `-translate-x-full`
          }`
        "
      >
        <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->

        <div class="flex flex-row justify-between content-center">
          <img
            class="h-12 pl-4 pt-3"
            src="/logo/logo-wide.png"
            alt="Workflow"
          />
          <div class="pt-3">
            <button
              type="button"
              @click="close"
              class="
                ml-1
                flex
                items-center
                justify-center
                h-12
                w-12
                rounded-full
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
              "
            >
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-12 w-12 text-brand-purple-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 36 36"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="mt-8 flex-1 flex flex-col justify-between h-0 overflow-y-auto"
        >
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <button
              @click="
                () => {
                  close();
                  $router.push({ name: item.router_name }).catch(() => {});
                }
              "
              :class="
                `text-gray-900 group flex items-center p-4 text-sm font-medium rounded-md font-serif ${
                  $router.currentRoute.name === item.router_name
                    ? 'font-extrabold'
                    : 'font-light'
                }`
              "
              v-for="(item, index) in items"
              :key="index"
            >
              <n-icon :name="item.icon" medium class="mr-4" />
              <n-html :text="$t(item.locale_key)" />
            </button>
          </nav>
          <div class="px-6 w-full">
            <DamageTheftButtons
              class="flex flex-row justify-between gap-2 pb-8"
              :extra-button-action="() => close()"
            />
            <div class="border-b border-gray-300 pb-4">
              <languageSelector/>
            </div>
            <div
              class="w-full font-serif pt-4 text-gray-500 font-light leading-5 text-sm"
            >
              <div class="flex flex-row">
                <n-html :text="$t('layout.common.sidebar.last_updated')" />
                :
                <div class="pl-4">
                  {{
                    new Date().toLocaleDateString("nl-NL", {
                      hour: "numeric",
                      minute: "numeric"
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="
          flex flex-col flex-grow
          border-r border-gray-100
          pt-5
          bg-white
          overflow-y-auto
        "
      >
        <div class="flex items-center flex-shrink-0 px-4">
          <img
            class="p-10 pt-3 mb-8"
            src="/logo/logo-sidebar.png"
            alt="Workflow"
          />
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-0.5 pb-4">
            <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <button
              @click="
                $router
                  .push({ name: item.router_name })
                  .catch(() => {})
                  .then(() => {
                    close();
                  })
              "
              :class="
                `text-brand-purple-500 group flex items-center px-7 my-6 text-sm font-medium font-serif cursor-pointer ${
                  $router.currentRoute.name === item.router_name
                    ? 'pl-6 font-extrabold border-l-2 border-brand-purple-500'
                    : 'font-light'
                }`
              "
              v-for="(item, index) in items"
              :key="index"
            >
              <n-icon :name="item.icon" medium class="mr-4" />
              <n-html :text="$t(item.locale_key)" />
            </button>
            <DamageTheftButtons class="lg:hidden flex flex-col gap-4 px-6" />
          </nav>
          <div class="mt-auto p-6 w-full">
            <div class="border-b border-gray-300 pb-4">
              <languageSelector/>
            </div>
            <div
              class="w-full text-center font-serif pt-4 text-gray-500 font-light leading-5 text-sm"
            >
              <div>
                <n-html :text="$t('layout.common.sidebar.last_updated')" />
                :
              </div>
              <div>
                {{
                  this.format_date(
                    this.customer.last_synchronized_at,
                    "DD-MM-YYYY HH:mm"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "../../../mixins/format";
import i18n from "../../../plugins/i18n";
import languageCodes from "@/mixins/languageCodes";
import DamageTheftButtons from "../button/damage-theft-buttons";
import languageSelector from "../../language/language-selector.vue";

export default {
  props: ["open", "close"],
  mixins: [format, languageCodes],
  components: {
    DamageTheftButtons,
    languageSelector
  },
  computed: {
    ...mapGetters({
      customer: "customer/get"
    })
  },

  data() {
    return {
      current: window.location.pathname,
      items: [
        {
          locale_key: "layout.common.sidebar.overview",
          icon: "Overzicht",
          router_name: "overview",
          url: "/"
        },
        {
          locale_key: "layout.common.sidebar.insurances",
          icon: "Verzekering",
          router_name: "insurances.index",
          url: "/insurances"
        },
        {
          locale_key: "layout.common.sidebar.claims",
          icon: "Claims",
          router_name: "claims.index",
          url: "/claims"
        },
        {
          locale_key: "layout.common.sidebar.payments",
          icon: "Betalingen",
          router_name: "payments.index",
          url: "/payments"
        },
        {
          locale_key: "layout.common.sidebar.service",
          icon: "Klantenservice",
          router_name: "service.index",
          url: "/customer_service"
        }
      ],
      currentLanguage: i18n.locale
    };
  },

  methods: {
    switchLanguage(e) {
      const lang = e.target.value;
      i18n.locale = lang;
      this.currentLanguage = lang;
    }
  }
};
</script>
