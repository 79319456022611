<template>
  <div class="min-h-screen bg-brand-gray-100">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <sidebar :open="sidebarOpen" :close="() => (sidebarOpen = false)" />

    <div class="md:pl-64 flex flex-col flex-1">
      <navbar :openSidebar="() => (sidebarOpen = true)" />

      <main class="flex-1">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import sidebar from "../components/theme/sidebar/sidebar.vue";
import navbar from "../components/theme/navbar/navbar.vue";
import format from "../mixins/format";

export default {
  components: {
    sidebar,
    navbar
  },
  name: "layouts.desktop",
  mixin: [format],
  data: () => ({
    w: {
      width: 0,
      height: 0
    },
    sidebarOpen: false,
    items: [
      {
        icon: "Overzicht",
        routerName: "overview",
        translationKey: "layout.common.sidebar.overview"
      },
      {
        icon: "Verzekering",
        routerName: "insurances.index",
        translationKey: "layout.common.sidebar.insurances"
      },
      {
        icon: "Claims",
        routerName: "claims.index",
        translationKey: "layout.common.sidebar.claims"
      },
      {
        icon: "Betalingen",
        routerName: "payments.index",
        translationKey: "layout.common.sidebar.payments"
      },
      {
        icon: "Klantenservice",
        routerName: "service.index",
        translationKey: "layout.common.sidebar.service"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      customer: "customer/get",
      title: "customer/getTitle",
      salutation: "customer/getSalutation"
    })
  },
  methods: {
    handleResize() {
      this.w.width = window.innerWidth;
      this.w.height = window.innerHeight;
    },
    getBoldClass(name) {
      return this.$router.currentRoute.name === name
        ? "menu-item selected-button"
        : "menu-item";
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    console.log(this.customer);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
