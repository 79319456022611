<template>
  <div
    class="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow sm:shadow-none"
  >
    <button
      type="button"
      @click="openSidebar"
      class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
    >
      <span class="sr-only">Open sidebar</span>
      <n-icon name="Mobile-menu" medium />
    </button>

    <div class="flex-1 px-4 flex items-center justify-end">
      <div class="group inline-block relative ml-auto">
        <button
          class="text-gray-700 font-semibold py-2 px-4 h-full rounded inline-flex items-center"
          @click="toggleDropdown"
        >
          <n-icon class="header-menu-icon" name="Avatar" large></n-icon>
          <div class="ml-4 mr-2">
            <n-html :text="$t('overview.welcome')" />, <n-html :text="title" />
            <n-html :text="salutation" />
          </div>
          <n-icon
            :name="dropdown === true ? 'Chevron-up' : 'Chevron-down'"
            small
          />
        </button>
        <!-- Profile dropdown -->
        <div class="ml-3 relative" :class="dropdown === true ? '' : 'hidden'">
          <div>
            <button
              type="button"
              class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="sr-only">Open user menu</span>
            </button>
          </div>

          <div
            class="origin-top-right absolute right-0 w-content rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <button
              @click="
                $router.push({ name: 'details.personal' }).catch(() => {})
              "
              class="block px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
            >
              <n-html :text="$t('layout.common.dropdown.personal_details')" />
            </button>
            <button
              @click="$router.push({ name: 'details.payment' }).catch(() => {})"
              class="block px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-1"
            >
              <n-html :text="$t('layout.common.dropdown.payment_details')" />
            </button>
            <button
              @click="logout"
              class="block px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
            >
              <n-html :text="$t('layout.common.dropdown.logout')" />
            </button>
          </div>
        </div>
      </div>
      <DamageTheftButtons class="space-x-4 hidden lg:block" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "../../../mixins/format";
import DamageTheftButtons from "../button/damage-theft-buttons";

export default {
  components: {
    DamageTheftButtons
  },
  props: ["openSidebar"],
  mixin: [format],
  computed: {
    ...mapGetters({
      customer: "customer/get",
      title: "customer/getTitle",
      salutation: "customer/getSalutation"
    })
  },
  data() {
    return {
      dropdown: false
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    logout() {
      this.$store.dispatch("application/logout");
    }
  },
  watch: {
    $route() {
      if (this.dropdown) {
        this.dropdown = false;
      }
    }
  }
};
</script>
